import React, { Component } from 'react'
import LinkWrap from 'components/LinkWrap'
import PackeryModule from 'react-packery-component'
import Layout from 'components/layout'
import Pagination from 'components/pagination'
import ImageWrap from 'components/ImageWrap'
import RelatedProjectsSlideshow from 'components/slideshows/relatedProjectsSlideshow'
import Title from 'components/title'
import { trFunction } from 'utils/functions'
import { graphql } from 'gatsby'

const Packery = PackeryModule(React)
const packeryOptions = {
  itemSelector: '.item-grid',
  percentPosition: true,
  gutter: '.gutter-sizer',
  transitionDuration: '0', //'0.4s'
}

const Collection = ({ node }) => {
  return (
    <div className="item-grid">
      <LinkWrap to={`/collections/collections/${node.slug}`}>
        <ImageWrap image={node.imageMain} aspectRatio={1.8125} />
      </LinkWrap>
      <h4>
        <LinkWrap to={`/collections/collections/${node.slug}`} className="dark">
          {node.title}
        </LinkWrap>
      </h4>
    </div>
  )
}

class Designer extends Component {
  render() {
    const locale = this.props.data.site.siteMetadata.locale
    const translations = this.props.data.translations
    const tr = (code) => trFunction(code, translations, locale)
    const designers = this.props.data.allContentfulDesigners.edges
    const designer = this.props.data.contentfulDesigners
    const page = this.props.data.contentfulPages
    const collections = this.props.data.allContentfulCollections
    return (
      <Layout>
        <div id="content">
          <Title
            subtitle={page.title}
            subtitleLink="/collections/designers"
            title={designer.title}
          />
          <section className="page-content">
            <div className="wrap">
              <div className="single-designer-info">
                <div className="col">
                  <div className="img-wrapper-container">
                    <ImageWrap image={designer.image} />
                  </div>
                </div>
                <div className="col">
                  {designer.content && (
                    <div
                      className="txt"
                      dangerouslySetInnerHTML={{
                        __html: designer.content.childMarkdownRemark.html,
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="line line-sep" />
              {collections && (
                <div className="wrapper-designer-collections">
                  <div className="small-caps section-title">
                    <LinkWrap to="/collections/collections" className="dark">
                      {tr('COLLECTIONS')}
                    </LinkWrap>
                  </div>
                  <Packery
                    className={'grid-packery grid-designer-collection'}
                    options={packeryOptions}
                  >
                    <div className="gutter-sizer" />
                    {collections.edges.map((edge, i) => (
                      <Collection key={i} node={edge.node} />
                    ))}
                  </Packery>
                </div>
              )}
              {designer.projects && (
                <RelatedProjectsSlideshow
                  projects={designer.projects}
                  sourcePage="designer"
                  tr={tr}
                />
              )}
              <Pagination
                slug={`collections/${page.slug}`}
                list={designers}
                item={designer}
                label="title"
                data={this.props.data}
              />
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}
export default Designer

export const designerQuery = graphql`
  query ($slug: String) {
    site {
      siteMetadata {
        locale
      }
    }
    translations: allContentfulStringTranslations {
      edges {
        node {
          code
          en
          zh
        }
      }
    }
    allContentfulDesigners(sort: { fields: [order], order: ASC }) {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
    contentfulDesigners(slug: { eq: $slug }) {
      id
      title
      slug
      content {
        childMarkdownRemark {
          html
        }
      }
      image {
        ...GatsbyImage
      }
      projects {
        title
        slug
        isFeatured
        datePosted
        location {
          id
          title
        }
        image {
          ...GatsbyImage
        }
      }
    }
    contentfulPages(slug: { eq: "designers" }) {
      title
      slug
    }
    allContentfulCollections(
      filter: { designer: { slug: { eq: $slug } } }
      sort: { fields: [order], order: ASC }
    ) {
      edges {
        node {
          title
          slug
          imageMain {
            ...GatsbyImage
          }
        }
      }
    }
  }
`
